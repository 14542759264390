<template>
    <Modal id="subscription-suspended" size="lg" :loading="true" :cancelable="active_step === 'payment'" @hidden="closed()">
        <div v-if="active_step === 'payment'">
            <IconBack
                v-if="active_step === 'payment' && page !== 'account'"
                class="position-absolute back-icon text-muted pointer"
                @click="showPlanNotice()"
            />
            <div>
                <h2 class="font-weight-bold mb-2 text-center">Card Details</h2>
            </div>
        </div>

        <PaymentDetails v-show="active_step === 'payment'" :page="page" />

        <PaymentConfirmation v-show="active_step === 'confetti'" :page="page" />

        <PlanSuspensionNotice v-show="active_step === 'plan_notice'" :page="page" @active-step="changeActiveStep" />
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Modal from '../../stories/components/modals/Modal.vue'
import PaymentDetails from '../checkout/PaymentDetails.vue'
import PaymentConfirmation from '../checkout/PaymentConfirmation.vue'
import PlanSuspensionNotice from '../checkout/PlanSuspensionNotice.vue'
import IconBack from '../../stories/components/icons/IconBack.vue'
import WithCheckout from '../../mixins/WithCheckout'

interface Data {
    active_step: string
}

export default defineComponent({
    name: 'SubscriptionSuspended',
    components: {
        IconBack,
        Modal,
        PaymentConfirmation,
        PaymentDetails,
        PlanSuspensionNotice,
    },
    mixins: [WithCheckout],
    props: {
        step: {
            type: String,
            default: 'plan_notice',
        },
    },
    data(): Data {
        return {
            active_step: this.step,
        }
    },
    async beforeMount() {
        if (this.active_step !== 'plan_notice') {
            return
        }

        await this.$store.dispatch('abtests/initialize', {
            completed: async () => {
                await this.$store.dispatch('abtests/loadSubscriptionSuspendOverlayCancelButtonColorTests')
            },
        })
    },
    methods: {
        changeActiveStep(step: string) {
            this.active_step = step
        },
        async closed() {
            await this.$store.dispatch('subscription/markPaymentFailureNotificationSeen', {
                subscription_id: this.subscription.id,
            })
        },
        showPlanNotice() {
            this.changeActiveStep('plan_notice')
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

#confetti {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.back-icon {
    top: 5px;
    left: 5px;
    height: 24px;
    width: 24px;
}
</style>
