<template>
    <div class="my-3 px-2">
        <h5 class="font-weight-bold text-center notice-title">Important Plan Update</h5>

        <h4 class="font-weight-bold text-center">Membership Paused⚠️</h4>

        <div class="text-center mt-3">
            <div class="d-flex justify-content-center f-14">
                Unfortunately, we're unable to charge the card on file. Your access to paid features has been paused.
                <br />
                To reactivate, please review and update your payment method.
            </div>
        </div>
        <div class="actions d-flex justify-content-center align-items-center">
            <button :class="`btn btn-${cancelButtonColor}`" @click="cancelPlan">Cancel Your Plan</button>
            <button class="btn btn-success ml-8" @click="reactivatePlan">Resume Your Plan</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import WithCheckout from '../../mixins/WithCheckout'
import OneClickUpgrade from '../../mixins/OneClickUpgrade'
import { PlanType } from '../../objects/Plan'

export default defineComponent({
    name: 'PlanSuspensionNotice',
    mixins: [WithCheckout, OneClickUpgrade],
    computed: {
        subscription(): any {
            return this.$store.state.subscription as any
        },
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            subscriptionSuspendOverlayCancelButtonColor: 'abtests/subscriptionSuspendOverlayCancelButtonColor',
        }),
        cancelButtonColor(): string {
            return this.subscriptionSuspendOverlayCancelButtonColor
        },
    },
    methods: {
        async cancelPlan() {
            this.closeModal()
            this.$bvModal.show('cancel-subscription')
        },

        async reactivatePlan() {
            this.setSelectedPlan(this.subscription.subscription_plan)
            this.setSelectedPlanType(this.planLabel as PlanType)
            this.$emit('active-step', 'payment')
        },

        async markNotificationSeen() {
            await this.$store.dispatch('subscription/markPaymentFailureNotificationSeen', {
                subscription_id: this.subscription.id,
            })
        },

        async closeModal() {
            this.$bvModal.hide('subscription-suspended')
            await this.markNotificationSeen()
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.btn {
    min-width: 9em;
    height: 3rem;
    font-size: 1.125rem;
}

@media (min-width: 991px) {
    .btn {
        min-width: 12em;
    }
}

.f-14 {
    font-size: 0.925rem;
}

.ml-8 {
    margin-left: 5rem;
}

.actions {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

.notice-title {
    margin-bottom: 2rem;
}
</style>
